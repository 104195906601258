import { SiGithub } from "@react-icons/all-files/si/SiGithub";
import { FaCog } from "react-icons/fa"; // Import settings icon
import { Link } from "components/link";
import clsx from "clsx";
import DarkmodeIcon from "components/darkmode-icon";
import { useTheme } from "next-themes";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation for i18n

type ProfileNavProps = {
  showNav: boolean;
};

export const ProfileNav: FC<ProfileNavProps> = ({ showNav }) => {
  const { theme, setTheme } = useTheme();
  const { t, i18n } = useTranslation("header"); // Use "header" namespace for translations
  const [showDropdown, setShowDropdown] = useState(false); // State to handle dropdown visibility

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleLanguageChange = (language: string) => {
    i18n.changeLanguage(language); // Change the language using i18next
    setShowDropdown(false); // Close the dropdown after selection
  };

  return (
    <nav className="z-10 ml-auto flex gap-1 pl-4">
      <button
        type="button"
        className={clsx(
          "rounded p-2 text-gray-500 transition-colors d:text-gray-300 d:h:text-gray-50 md:h:text-gray-900",
          showNav ? "h:text-gray-200" : "h:text-gray-900"
        )}
        onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
      >
        <span className="sr-only">{t("theme_toggle")}</span>
        <DarkmodeIcon />
      </button>
      <Link
        href="https://github.com/AyoubOUASSAF"
        className={clsx(
          "rounded p-2 text-gray-500 transition-colors d:text-gray-300 d:h:text-gray-50 md:h:text-gray-900",
          showNav ? "h:text-gray-200" : "h:text-gray-900"
        )}
      >
        <span className="sr-only">{t("github")}</span>
        <SiGithub className="h-5 w-5" />
      </Link>
      <button
        type="button"
        className="relative rounded p-2 text-gray-500 transition-colors d:text-gray-300 md:h:text-gray-900"
        onClick={toggleDropdown}
      >
        <FaCog className="h-5 w-5" />
        <span className="sr-only">{t("settings")}</span>
        {showDropdown && (
          <div className="absolute right-0 mt-2 w-36 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <ul className="py-1">
              <li className="px-4 py-2 text-sm text-gray-700">
                {t("current_language")}: {i18n.language.toUpperCase()}
              </li>
              {Object.entries(t("language", { returnObjects: true })).map(([key, value]) => (
                <li key={key}>
                  <button
                    className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                    onClick={() => handleLanguageChange(key)}
                  >
                    {value}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </button>
      <Link
        target="_blank"
        href="mailto:ouassaf.ayoub@gmail.com"
        className="button-rainbow ml-4 hidden whitespace-nowrap px-4 py-1.5 text-sm font-medium tracking-tight text-gray-500 md:flex"
      >
        {t("lets_work")}
      </Link>
    </nav>
  );
};
