import { TFunction } from "i18next";
import { useTheme } from "next-themes";
import React from "react";
import LogoB from "public/logo-b.svg"; // Black logo
import LogoW from "public/logo-w.svg"; // White logo

// Accept TFunction as an argument
export const HEADER = (t: TFunction) => ({
  logo: {
    href: "/",
    title: () => {
      const { theme } = useTheme();
      return (
        <div>
          {theme === "dark" ? (
            <LogoW alt={t("logo_alt")} />
          ) : (
            <LogoB alt={t("logo_alt")} />
          )}
        </div>
      );
    },
    alt: t("logo_alt"),
  },
  nav: [
    {
      href: "/",
      title: t("nav.home.title"),
      alt: t("nav.home.alt"),
      desktop: false,
    },
    {
      href: "/#about",
      title: t("nav.about.title"),
      alt: t("nav.about.alt"),
      desktop: true,
    },
    {
      href: "/#portfolio",
      title: t("nav.portfolio.title"),
      alt: t("nav.portfolio.alt"),
      desktop: true,
    },
    {
      href: "/resume",
      title: t("nav.resume.title"),
      alt: t("nav.resume.alt"),
      desktop: true,
    },
  ],
  profileNav: [],
});
