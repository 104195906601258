import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(Backend) // Load translations using HTTP backend
  .use(LanguageDetector) // Automatically detect user language
  .use(initReactI18next) // Bind to React
  .init({
    debug: true, // Enable debug mode in development

    // Core i18next options
    fallbackLng: "en", // Fallback language if detection fails
    supportedLngs: ["en", "fr"], // Supported languages
    ns: ["translation", "hero", "portfolio", "header","about"], // Namespaces for translations
    defaultNS: "translation", // Default namespace

    interpolation: {
      escapeValue: false, // React already escapes by default
    },

    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json", // Path to translation files
      addPath: "/locales/add/{{lng}}/{{ns}}", // Path for saving missing keys
    },

    detection: {
      order: ["querystring", "localStorage", "cookie", "navigator"], // Detection priority
      caches: ["localStorage", "cookie"], // Cache the detected language
    },

    react: {
      useSuspense: false, // Disable suspense; handle loading manually
    },

    saveMissing: true, // Save missing keys (development only)
  });

export default i18n;
