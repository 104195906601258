export const SEO = {
  title: "Ayoub OUASSAF - Fullstack Developer - TS, Next, Tailwind, Shopify , Wordpress , React",
  url: "https://ayoubdev.pro",
  description:
    "Born and raised in Morocco, I now live in Aix-en-Provence, France, after spending time in Turin, Italy. I combine my passion for technology, creativity, and entrepreneurship in my daily life",
  siteName: "ayoubdev..pro",
  github: "https://github.com/AyoubOUASSAF",
  avatar: "https://avatars.githubusercontent.com/AyoubOUASSAF",
  author: "Ayoub OUASSAF",
  twitter: {
    handle: "@ayoubouassaf",
    site: "@AyoubOUASSAF",
    cardType: "summary_large_image",
  },
  openGraph: {
    type: "website",
    locale: "en_IE",
    url: "https://ayoubdev.pro",
    site_name: "ayoubdev.pro",
    title: "Ayoub OUASSAF",
    description:
      "Lets make things better with quality code - Learn Web Development / API's / Automations / Serverless / Architecture / and more.",
    images: [
      {
        url: "https://flext.dev/images/sharing-image.jpg",
        alt: "Ayoub OUASSAF - Full-stack Engineer - React.js, Typescript, Tailwindcss, Wordpress",
        width: 1200,
        height: 630,
      },
    ],
  },
};

export const BLOG_SEO = {
  title: "Ayoub OUASSAF - Blog",
  description:
    "Learn Web Development - TypeScript / React / APIs / Automations / Serverless / Architecture / and more. - Lets make things better with quality code",
  openGraph: {
    title: "Ayoub OUASSAF - Blog",
    description:
      "Learn Web Development - TypeScript / React / APIs / Automations / Serverless / Architecture / and more. - Lets make things better with quality code",
  },
};
