import { trpc } from "components/_app/trpc";
import { ContextProviders } from "components/_stores/_context-providers";
import { LoadInitialData } from "components/_stores/_load-initial-data";

import { Footer } from "components/layout/footer";
import { Header } from "components/layout/header";
import { SEO } from "content/seo";
import { DefaultSeo } from "next-seo";
import { AppProps } from "next/app";
import { useRouter } from "next/router";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import "styles/tailwind.css";

// Import i18n
import "../utils/i18n";
import { I18nextProvider } from "react-i18next";
import i18n from "../utils/i18n";

const Loaders: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ContextProviders>
      <LoadInitialData>{children}</LoadInitialData>
    </ContextProviders>
  );
};

const App = ({ pageProps, Component }: AppProps) => {
  const router = useRouter();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (window) {
      setLoading(false);
      if (process.env.NODE_ENV !== "development") {
        console.log(
          "%cHey there, curious developer! 👀\n\nThis page is handcrafted with love by %cAyoub OUASSAF%c.\n\nBuilt using the latest and greatest tech:\n⚡ %cNext.js\n🎨 TailwindCSS\n📜 TypeScript\n🚀 Vercel\n🔗 TRPC\n\nExplore, get inspired, and feel free to connect!\n\n📬 Email: ouassaf.ayoub@gmail.com\n🌐 Website: ayoubdev.pro",
          "color: #1e40af; font-size: 16px; font-weight: bold; line-height: 1.5;",
          "color: #10b981; font-size: 16px; font-weight: bold; line-height: 1.5;",
          "color: #1e40af; font-size: 16px; font-weight: bold; line-height: 1.5;",
          "color: #ef4444; font-size: 14px; font-weight: bold;"
        );
      }
    }
  }, []);

  if (loading) {
    return <></>;
  }

  return (
    <I18nextProvider i18n={i18n}>
      <Loaders>
        <DefaultSeo
          {...SEO}
          canonical={`${SEO.url}${router.asPath}`}
          twitter={SEO.twitter}
          title={SEO.title}
          description={SEO.description}
          openGraph={SEO.openGraph}
        />
        <Header />
        <main className="min-h-screen print:!mx-auto print:!w-[1024px]">
          <Component {...pageProps} />
        </main>
        <Footer />
        {/*<Stars />*/}
      </Loaders>
    </I18nextProvider>
  );
};

export default trpc.withTRPC(App);
