import { Link } from "components/link";
import { DesktopNav } from "components/layout/header.desktop-nav";
import { MobileNav } from "components/layout/header.mobile-nav";
import { ProfileNav } from "components/layout/header.settings";
import { HEADER } from "content/layout";
import { FC, useState } from "react";
import { useTheme } from "next-themes";
import LogoB from "public/logo-b.svg"; // Black logo for light theme
import LogoW from "public/logo-w.svg"; // White logo for dark theme

export const Header: FC = ({}) => {
  const [showNav, setShowNav] = useState(false);
  const { theme } = useTheme(); // Access the current theme

  return (
    <>
      <header className="fixed inset-x-0 top-0 z-50 h-20 w-full border-b border-gray-800/10 bg-white/50 backdrop-blur d:border-gray-100/10 d:bg-gray-900/40 print:hidden">
        <div className="mx-auto flex h-full max-w-6xl grid-cols-[210px_1fr_210px] items-center gap-1 px-4 md:grid md:gap-4 md:px-8">
          <Link
            href="/"
            className="z-10 w-min"
            data-tip={"Hi, I'm Ayoub. Welcome to my site."}
            data-delay-show={2000}
          >
            <span className="sr-only">ayoubdev Logo</span>
            {/* Dynamically render the logo based on the current theme */}
            {theme === "dark" ? <LogoW alt="ayoubdev Logo White" /> : <LogoB alt="ayoubdev Logo Black" />}
          </Link>
          <DesktopNav />
          <ProfileNav showNav={showNav} />
          <MobileNav showNav={showNav} setShowNav={setShowNav} />
        </div>
        <div className="background pointer-events-none absolute inset-0 z-0 select-none ">
          {/* Optional background elements can go here */}
        </div>
      </header>
      <div className="pointer-events-none h-20 select-none transition print:hidden" />
    </>
  );
};
